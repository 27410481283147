<template>
  <div>
    <div class="gradationText" :style="{ 'font-size': size }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: '',
      type: String,
    },
    size: {
      default: '',
      type: String,
    },
  }
}

</script>

<style lang="scss">
@media screen and (min-width: 1600px) {
  .gradationText {
    user-select: none;
    // background: linear-gradient(90deg, #2b5aaa, #fff, #2b5aaa);
    background: #2f6bcf;

    background-size: 200% 200%;
    // animation: gradientAnimation 2s ease infinite;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-family: fontLED1;
    text-align: center;
    line-height: 70px;
  }
}

@media screen and (max-width: 1600px) {
  .gradationText {
    user-select: none;
    // background: linear-gradient(90deg, #2b5aaa, #fff, #2b5aaa);
    background: #2f6bcf;

    background-size: 200% 200%;
    // animation: gradientAnimation 2s ease infinite;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-family: fontLED1;
    text-align: center;
    line-height: 70px;
    font-size: 100% !important;
  }
}
@media screen and (max-width: 900px) {
  .gradationText {
    user-select: none;
    // background: linear-gradient(90deg, #2b5aaa, #fff, #2b5aaa);
    background: #2f6bcf;

    background-size: 200% 200%;
    // animation: gradientAnimation 2s ease infinite;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-family: fontLED1;
    text-align: center;
    line-height: 70px;
    font-size: 30% !important;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>
