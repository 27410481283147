import intelligent from "@/views/intelligentsystem/views/layout/index"

export default {
    intelligentrouterData:[{
        path: "/intelligentsystem",
        name: "intelligentsystem",
        hidden: true,
        redirect: '/intelligentsystem/home',
        meta: {
            title: '工作台',
        },
        component: intelligent,
        children: [
            {
            path: '/intelligentsystem/home',
            name: 'intelligentsystemHome',
            icon: "btn-icon-1.png",
            component: () => import('@/views/intelligentsystem/views/home/index.vue'),
            meta: {
                title: '首页',
            },
        },
        // {
        //     path: '/intelligentsystem/realData',
        //     name: 'intelligentsystemrealData',
        //     icon: "btn-icon-2.png",
        //     component: () => import('@/views/intelligentsystem/views/realData/index.vue'),
        //     meta: {
        //         title: '实时数据',
        //     },
        // },
        {
            path: '/intelligentsystem/alarmData',
            name: 'intelligentsystemalarmData',
            icon: "btn-icon-3.png",
            // redirect: '/intelligentsystem/alarmData/alarmData1',
            component: () => import('@/views/intelligentsystem/views/alarmData/index.vue'),
            meta: {
                title: '报警数据',
            },
            // children: [{
            //     path: '/intelligentsystem/alarmData/alarmData1',
            //     name: 'intelligentsystemalarmData1',
            //     icon: "btn-icon-1.png",
            //     component: () => import('@/views/intelligentsystem/views/alarmData/index1.vue'),
            //     meta: {
            //         title: '报警数据1',
            //     },
            // },{
            //     path: '/intelligentsystem/alarmData/alarmData2',
            //     name: 'intelligentsystemalarmData2',
            //     icon: "btn-icon-1.png",
            //     component: () => import('@/views/intelligentsystem/views/alarmData/index2.vue'),
            //     meta: {
            //         title: '报警数据2',
            //     },
            // }]
        },{
            path: '/intelligentsystem/dataShow',
            name: 'intelligentsystemdataShow',
            icon: "btn-icon-4.png",
            component: () => import('@/views/intelligentsystem/views/dataShow/index.vue'),
            meta: {
                title: '数据展示',
            },
        },{
            path: '/intelligentsystem/pathShow',
            name: 'intelligentsystempathShow',
            icon: "btn-icon-5.png",
            component: () => import('@/views/intelligentsystem/views/pathShow/index.vue'),
            meta: {
                title: '轨迹展示',
            },
        },{
            path: '/intelligentsystem/ring',
            name: 'intelligentsystemring',
            icon:"btn-icon-6.png",
            component: () => import('@/views/intelligentsystem/views/ring/index.vue'),
            meta: {
                title: '动环监控',
            },
        },
        {
            path: '/intelligentsystem/Smart',
            name: 'intelligentsystemSmart',
            icon:"btn-icon-7.png",
            component: () => import('@/views/intelligentsystem/views/Smart/index.vue'),
            meta: {
                title: '智慧大屏',
            },
        },{
            path: '/intelligentsystem/plan',
            name: 'intelligentsystemplan',
            icon:"btn-icon-3.png",
            component: () => import('@/views/intelligentsystem/views/plan/index.vue'),
            meta: {
                title: '平面图',
            },
        },{
            path: '/intelligentsystem/visual',
            name: 'intelligentsystemvisual',
            icon:"btn-icon-6.png",
            component: () => import('@/views/intelligentsystem/views/visual/index.vue'),
            meta: {
                title: '3D可视化',
            },
        },{
            path: '/intelligentsystem/myinfo',
            name: 'myinfo',
            icon:"btn-icon-2.png",
            component: () => import('@/views/intelligentsystem/views/myinfo/index.vue'),
            meta: {
                title: '个人中心',
            },
        }]
    }]
}