<template>
	<div class="layou-home">
		<LockScreen v-show="showModel"></LockScreen>
		<!-- v-on:dblclick="doubleclick" -->
		<div class="layou-home-top">
			<Topmenu v-show="show2" class="layout-nav"></Topmenu>
		</div>
		<div class="layou-home-bottom">
			<!-- <div class="layou-home-bottom-nav">
				<navigations></navigations>
			</div> -->
			<div class="layou-home-bottom-pir">
				<router-view></router-view>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	// import navigations from './navigations.vue'
	import Topmenu from './Topmenu.vue'
	import LockScreen from '@/components/LockScreen.vue'
	export default {
		computed: mapGetters(["showModel"]),
		data() {
			return {
				Navlist: [],
				show2: true,
				modexTime:0
			}
		},
		components: {Topmenu,LockScreen},
		methods: {
			
			doubleclick() { //双击显示隐藏
				////console.log('双击')
				this.show2 = !this.show2
			}
		}
	}
</script>

<style lang="scss">
	.layou-home {
		height: 100vh;
		display: flex;

		.layou-home-top {
			// position: sticky;
			// top: 0;
			// z-index: 1;
			// border: 1px solid red;
			overflow-y: auto;
            // border: 1px solid red;
		}

		.layou-home-bottom {
			flex: 1;
			overflow: auto;
			// background: rgba(#f9f9f9, 1);
			background: rgba(#fff, 1);

			.layou-home-bottom-nav {
				background: rgba(#fff, 1);
				margin: 0 10px;
				border-radius: 10px;
			}

			.layou-home-bottom-pir {
				flex: 1;
				overflow-y: hidden;
			}
		}
	}
</style>
