import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'stort',
			paths: ['userInfo',"SETPerList",'showFirm','showData','permission']
		})
	],
	state: {
		showModel:false,
		nav:[],//导航
		userInfo: {
			username: '',
			password: '',
			token: ''
		},
		showData: {//获取数据展示中的 iframe链接所需的参数
			T_data: '',
			T_plan: '',
			T_v3d: ''
		},
		showFirm:{
			plan:'',
			T_name:'',
			bl: false
		},
		permission:[],//按钮权限列表
		searchs:false,//是否显示搜索弹窗
	},
	getters:{//计算属性
		permission:state=>state.permission,
		showData:state=>state.showData,
		navData:state=>state.nav,
		showFirm:state=>state.showFirm,
		showModel:state=>state.showModel

	},
	mutations: {
		SETMODEL(state,data){//按钮权限列表
			state.showModel = data
		},
		SETPER(state,data){//按钮权限列表
			state.permission = data
		},
		SETDATA(state,data){//获取数据展示中的 iframe链接所需的参数
			state.showData = data
		},
		SETPANY(state,data){///////////////////
			state.showFirm = data
		},
		SETNAV(state,data){///////////////////
			state.nav = data
			//console.log('重新设置菜单2',data)
		},
		setuserInfo(state, data) {
			state.userInfo = data
		},
	},
	actions:{//管理mutations
		SETMODEL({commit},data){
			commit('SETMODEL',data)
		},
		SETPER({commit},data){
			commit('SETPER',data)
		},
		SETNAV({commit},data){
			//console.log('重新设置菜单1',data)
			commit('SETNAV',data)
		},
		SETPANY({commit},data){
			commit('SETPANY',data)
		},
		SETDATA({commit},data){//获取数据展示中的 iframe链接所需的参数
			commit('SETDATA',data)
		},
	},
	modules: {},
});
