<template>
	<div class="intelligentsystem-layout">
		<headers v-if="showNav" data-aos="slide-down" data-aos-delay="0"></headers>
		<div style="flex: 1;overflow-y: auto;">
			<router-view></router-view>
		</div>
		<navMenu></navMenu>
	</div>
</template>

<script>
import headers from "@/views/intelligentsystem/components/headers.vue";

import navMenu from "./navMenu.vue";
import arrow from "./arrow.vue";


export default {
	components: { headers, navMenu,arrow },
	data() {
		return {
			routerArray:['/intelligentsystem/Smart','/intelligentsystem/plan','/intelligentsystem/visual'],
			showNav:true
		}
	},
	watch:{
        "$route":{
			handler(to,from){
				this.routerArray.includes(to.path)?this.showNav=false:this.showNav = true
			},immediate:true,deep:true
		}
    },
	methods: {
		clickshowNav(){
			this.showNav = true
		}
	}
}
</script>

<style lang="scss">
@import url('../../assets/scss/layout.scss');
</style>
