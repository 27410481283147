<!-- 底部导航 -->
<template>
    <div class="navMenus" @click="showMenuFn">
        <template v-if="screenWidth>=1163">
            <div class="navMenus-item" v-for="(item,index) in listMenu" :key="index" @click="clickbtn(item.path)">
                <router-link  class="navMenus-item-link" :to="item.path">
                    <div class="navMenus-item-img" :class="activesIt==item.path?'navMenus-item-icons-actives':'navMenus-item-icons'">
                        <img :src="imageSrc(item.icon)" style="width: 100%;height: 100%;">
                    </div>
                    <div class="navMenus-item-text">
                        {{ item.meta.title }}
                    </div>
                </router-link>
            </div>
        </template>
        <template v-else>
            <div class="navMenus-item">
                <div class="navMenus-Mobile"v-show="showMenu">
                    <div class="navMenus-item" v-for="(item,index) in listMenu" :key="index" @click="clickbtn(item.path)">
                        <router-link  class="navMenus-item-link" :to="item.path">
                            <div class="navMenus-item-img" :class="activesIt==item.path?'navMenus-item-icons-actives':'navMenus-item-icons'">
                                <img :src="imageSrc(item.icon)" style="width: 100%;height: 100%;">
                            </div>
                            <div class="navMenus-item-text">
                                {{ item.meta.title }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <i class="el-icon-menu" style="font-size: 40px;" @click.stop="showMenuFn"></i>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            showMenu:false,
            activesIt:'',
            listMenu:[],
            screenWidth:document.body.clientWidth
        }
    },
    watch:{
        "$route"(to,from){
            this.activesIt = to.path
        }
    },
    created(){
        this.listMenu = this.$router.options.routes[4].children
        this.activesIt = this.$route.path
    },
    mounted(){
        window.onresize = () => (() => {
            this.screenWidth = document.body.clientWidth
            if(this.screenWidth>=1163)this.showMenu = false
            
        })();
    },
    methods:{
        showMenuFn(){
            this.showMenu = !this.showMenu
        },
        clickbtn(path){
            this.activesIt = path
        },
        imageSrc(imageSrc) { 
            return require(`../../assets/img/${imageSrc}`) 
        }  
    }
}
</script>
<style lang="scss">
@import url('../../assets/scss/navMenu.scss');
</style>