<!-- 模态框 -->
<template>
    <div class="Modalbox" v-if="visible" @click.stop="hideModel">
        <div class="Modalbox-mais" :style="{width:iswidth,'min-height':minHeihts,height:isheight}" data-aos="zoom-in" data-aos-delay="0" @click.stop="">
            <div class="titlebox">{{ titleBox }}</div>
            <div class="conterbox">
                <slot name="modalConter"></slot>    
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['visible','titleBox','iswidth'], // 接收父组件传递的值
    props:{
        visible:{
            type:Boolean,
            default:true
        },
        titleBox:{
            type:String,
            default:''
        },
        iswidth:{
            type:String,
            default:'400px'
        },
        isheight:{
            type:String,
            default:'80%'
        },
        minHeihts:{
            type:String,
            default:'700px'
        }
    },
    data() {
        return {
            
        }
    },
    created() {

    },
    methods:{
        hideModel(){
            this.$emit('update:visible', false)
        }
    }
}
</script>
<style lang="scss">
@import url('../assets/scss/Modalbox.scss');
</style>