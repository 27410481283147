<!-- 按钮 -->
<template>
    <div class="btn" @click="clickFn">
        {{text}}
    </div>
</template>

<script>
export default {
    props:{
        text:{
            default: '',
            type: String,
        },
    },
    methods:{
        clickFn(){
            this.$emit('click',this.text)
        }
    },
    data() {
        return {

        }
    },
}
</script>
<style lang="scss">
.btn{
    padding: 5px 20px;
    background: url('../assets/img/btns.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    user-select: none;
    color:#5fc5ff;
    transition: all .3s;
}
.btn:hover{
   transform: scale(1.1);
}
</style>