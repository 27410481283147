<template>
	<div class="Breadcrumbs">
		<!-- <el-page-header @back="goBack" title="首页">
		</el-page-header> -->
		<div class="Breadcrumbs1">
			{{BreData.BreData1}}
		</div>
		<div class="Breadcrumbs2">
			<i class="el-icon-arrow-right"></i>
		</div>
		<div class="Breadcrumbs3">
			{{BreData.BreData2}}
		</div>
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
		  <el-breadcrumb-item></el-breadcrumb-item>
		  <el-breadcrumb-item></el-breadcrumb-item>
		</el-breadcrumb> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				BreData: {
					BreData1: '',
					BreData2: ''
				}
			}
		},
		mounted() {
			this.BreData.BreData1 = this.$route.matched[1].meta.title
			this.BreData.BreData2 = this.$route.meta.title
		},
		methods: {
			goBack() {
				this.$router.push({
					path: '/index'
				})
			}
		}
	}
</script>

<style lang="scss">
	.Breadcrumbs {
		display: flex;
		align-items: flex-end;
		margin-right: 20px;
		.Breadcrumbs1 {
			font-size: 24px;
			color:#ee954d !important;
		}

		.Breadcrumbs2 {
			margin: 0 5px;
			font-size: 14px;
			margin-bottom: 2px;
			color: #909399;
		}

		.Breadcrumbs3 {
			font-size: 14px;
			margin-bottom: 2px;
			color: #909399;
		}
	}
</style>
