<template>
    <div class="no-data">  
            <div class="no-data-item">  
                <div class="no-data-text" style="color: #2F6BCF;">{{ titles }}</div>
            </div>
        </div>
</template>
<script>
    export default {
        props:{
            titles:{
                type:String,
                default:'哎呀！暂无数据可展示哦'
            }
        }
    }
</script>
<style lang="scss">
.tables {
    width: 100%;
}

.no-data{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    min-height: 400px;
}
.no-data-item{
    position: relative;
    background: url('../assets/img/bg-1.png');
    background-size: 100% 100%;
    background-position: center center;
    min-width:450px;
    min-height:200px;
    display: flex;
    justify-content:center;
    align-items: center;
}
</style>