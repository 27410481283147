import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/highcharts';
import AOS from "aos";
import {} from "@/permission.js"
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

export const EventBus = new Vue();


import "../node_modules/aos/dist/aos.css";
import AMap from 'vue-amap'
Vue.use(AMap)
AMap.initAMapApiLoader({
	key: '4e50fb6e680b732b59887ba2893f012f',
	plugin: ['AMap.Autocomplete' ,'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
	// 默认高德 sdk 版本为 1.4.4
	v: '1.4.4'
})
window._AMapSecurityConfig = {
	securityJsCode: "4ddae7ac063e1cdea8b4ebcdfd0d2dab"
};

// 全局注册 Modalbox 智慧系统弹窗组件  
import Modalbox from '@/views/intelligentsystem/components/Modalbox.vue' 
Vue.component('my-Modalbox', Modalbox)  

// 全局注册 Modalbox 智慧系统表格组件  
import tables from '@/views/intelligentsystem/components/tables.vue' 
Vue.component('my-tables', tables) 

// 全局注册 Modalbox 智慧系统分页组件  
import pagination from '@/views/intelligentsystem/components/pagination.vue' 
Vue.component('my-pagination', pagination) 

// 全局注册 Modalbox 智慧系统分页组件  
import Titlebar from '@/views/intelligentsystem/components/Titlebar.vue' 
Vue.component('my-Titlebar', Titlebar) 
// 全局注册 Modalbox 智慧系统按钮组件  
import btns from '@/views/intelligentsystem/components/btn.vue' 
Vue.component('my-btn', btns) 

// 全局注册 Modalbox 智慧系统卡片组件  
import cards from '@/views/intelligentsystem/components/cards.vue' 
Vue.component('my-cards', cards) 

// 全局注册 Modalbox 智慧系统抽屉组件  
import Drawers from '@/views/intelligentsystem/components/Drawers.vue' 
Vue.component('my-drawers', Drawers) 

// 全局注册 Modalbox 智慧系统抽屉组件  
import noDatas from '@/views/intelligentsystem/components/noData.vue' 
Vue.component('my-noData', noDatas) 

// import './components/install'
// 引入echarts
import * as echarts from "echarts";
import highchartspirH from "./components/highcharts-h.vue" //highcharts二次封装
Vue.component('highchartspirH', highchartspirH)

import highchartspirT from "./components/highcharts-t.vue" //highcharts二次封装
Vue.component('highchartspirT', highchartspirT)
import './assets/iconfont/iconfont.css';

import Breadcrumb from "./components/Breadcrumbs.vue" //全局搜索框
Vue.component('Breadcrumb', Breadcrumb)

import inputs from "./components/inputs.vue" //全局搜索框
Vue.component('inputs', inputs)

import noData from "./components/noData.vue" //暂无数据
Vue.component('noData', noData)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
// import VueColumnsResizableVuetify from '../node_modules/_vue-columns-resizable-vuetify@1.0.3@vue-columns-resizable-vuetify'; //table可拖动宽带
// Vue.use(VueColumnsResizableVuetify);

Vue.prototype.$echarts = echarts;
Vue.use(AOS)
Vue.config.productionTip = false;
Vue.use(ElementUI);

// 注册为全局组件，该项目的所有页面都可以直接使用<searchs></searchs>

// Vue.prototype.loading = true; //权限ID

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
