<template>
	<div class="noData">
		<img class="errimg" src="../assets/img/nodata.png" >
		<p>抱歉，没有其他可展示的数据哦</p>
	</div>
</template>

<script>
	export default {
		name: 'noData',
	}
</script>

<style lang="scss">
	.noData{
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		user-select: none;
		.noDataimg{
			width: 300px;
			height: 300px;
		}
	}
</style>
