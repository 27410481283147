<template>
    <div class="tables">
        <el-table v-if="tableData.length>0" :data="tableData"
            @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column v-if="checkbox" type="selection" width="60">
            </el-table-column>
            <el-table-column type="index" width="60" class="index-column" label="序号" v-if="indexType">
            </el-table-column>
            <template v-for="item in headers">
                <el-table-column v-if="!item.name && item.key != 'option'" :prop="item.key" :label="item.label" :width="item.width" :show-overflow-tooltip="true">
                </el-table-column>
                <!-- 自定义slot -->
                <el-table-column
                    v-bind="item"
                    v-if="item.key && item.name === item.key"
                    v-slot="scope" :show-overflow-tooltip="true"
                >
                    <slot :name="item.key" :row="scope.row"></slot>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column
                    v-bind="item"
                    v-if="item.key === 'option'"
                    v-slot="scope" :show-overflow-tooltip="true"
                    :min-width="item.width"
                >
                    <slot name="right" :row="scope.row"></slot>
                </el-table-column>
            </template>
            
        </el-table>
        <div v-else class="no-data">  
            <div class="no-data-item">  
                <div class="no-data-text" style="color: #2F6BCF;">哎呀！暂无数据可展示哦</div>
            </div>
        </div>
        <my-pagination style="margin-top: 20px;" v-if="pagination && total" :total="total" :pageSizi="initParam.page_z"
            @currentChange="handleCurrentChange" :PageNum="initParam.page"></my-pagination>
    </div>
</template>
<script>
export default {
    name: 'MyTable',
    props: {
        headers: {//渲染头部
            type: Array,
            required: true,
            validator: function (value) {
                return value.every(header => header.key && header.label);
            }
        },
        requestApi: {//接口
            type: Function,
            default: () => { }
        },
        initParam: {//请求参数
            type: Object,
            default: {}
        },
        pagination: {//是否显示分页
            type: Boolean,
            default: true
        },
        checkbox: {//是否显示勾选
            type: Boolean,
            default: true
        },
        indexType: {//是否显示索引
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            tableData: [],
            total: 0,
        }
    },
    methods: {
        handleSelectionChange(val) {  
            this.$emit('checkboxFun',val)
        },
        handleCurrentChange(e){
            this.initParam.page = e
            this.callPropFunction() 
        },
        async callPropFunction() {
            const reslut = await this.requestApi({ ...this.initParam })
            this.total = reslut.data.Data==null?0:reslut.data.Data.Num
            this.$emit('update:total',this.total );
            this.tableData = reslut.data.Data==null?[]:reslut.data.Data.Data || []
        }
    },

}
</script>
<style scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
}

/* 设置 el-table 的背景色为透明 */
::v-deep .el-table {
    background-color: transparent !important;
}

/* 设置 el-table 的表头背景色 */
::v-deep .el-table__header-wrapper th {
    background-color: rgba(133, 170, 233, 0.08) !important;
    /* 将 #your-color 替换为你想要的颜色 */
    /* font-size: 16px; */
    color: #03aff6;
}

::v-deep .el-table tr {
    background-color: transparent !important;
}

/* 设置表格行的背景色为透明 */
::v-deep .el-table__body tr {
    background-color: transparent !important;
    color: #03aff6;
}

/* 如果需要，也可以设置鼠标悬停时的行背景色为透明 */
::v-deep .el-table__body tr:hover>td {
    background-color: rgba(133, 170, 233, 0.08) !important;
}

::v-deep .el-table__cell {
    border-bottom: none !important;
}


/**去掉table底部的横线 */
::v-deep .el-table::before {
    height: 0;
}


::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 1px solid #03aff6;
}
  
/* 修改勾选框的伪元素颜色（如果需要） */  
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {  
    border-color:#03aff6; /* 勾选标记的颜色 */  
}
::v-deep .el-table__cell .cell {
    text-align: center !important;
}
</style>

<style lang="scss">
.tables {
    width: 100%;
}

.no-data{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    min-height: 400px;
}
.no-data-item{
    position: relative;
    background: url('../assets/img/bg-1.png');
    background-size: 100% 100%;
    background-position: center center;
    min-width:450px;
    min-height:200px;
    display: flex;
    justify-content:center;
    align-items: center;
}
</style>