<template>
	<div class="showFirm">
		<transition name="el-zoom-in-top">
			<div v-show="showFirm.bl && showFirm.T_name" class="transition-box xianshi" title="双击可关闭" @dblclick="hideFirm">
				你好，欢迎进入【{{showFirm.T_name}}】
			</div>
		</transition>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	import { showFirmArr } from "@/plugins/showFirm";
	export default {
		computed: mapGetters(["showFirm"]),
		data() {
			return {
			}
		},
		watch:{
			"$route":{
				handler(to){
					showFirmArr.includes(to.path)?
					this.$store.dispatch('SETPANY', {T_name:this.$store.state.showFirm.T_name,bl:false,plan:this.$store.state.showFirm.plan}):
					this.$store.dispatch('SETPANY', {T_name:this.$store.state.showFirm.T_name,bl:true,plan:this.$store.state.showFirm.plan})
				},immediate:true,deep:true
			}
		},
		methods:{
			hideFirm(){
				this.$store.dispatch('SETPANY', {T_name:this.showFirm.T_name,bl:false})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.showFirm{
		user-select: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 0;
		display: flex;
		justify-content: center;
		.xianshi{
			border-radius: 8px;
			box-sizing: border-box;
			border: 1px solid #ebeef5;
			background-color: #fff;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
			color: rgb(117, 116, 117);
			font-size: 12px;
			display: flex;
			flex-direction: column;
			margin-top: 10px;
			cursor: pointer;
			height: 30px;
			padding: 0 30px;
			line-height: 30px;
		}
		.showFirm-ico{
			color: red;
		}
	}
</style>
