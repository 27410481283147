import Vue from "vue"
import store from "@/store/index.js"
export const permission = Vue.directive('permission',{
	inserted(el,binding){//inserted被绑定元素插入父元素时调用，el为绑定的元素，binding指令的绑定值 例 add del
		let perKey = binding.value
		if(perKey){
			let key = checkBtn(perKey)
			if(!key){//没有权限
				el.remove()  //删除按钮
			}
		}else{
			//console.log('缺少指令')
		}
	}
})

export function pertabs(key){
	if(key){
		return checkBtn(key)
	}else{
		//console.log('缺少指令')
	}
}

function checkBtn(key){
	const permissionData = store.state.permission?store.state.permission:[]
	let index = permissionData.indexOf(key) //检查传入的值是不是在里面
	if(index > -1) {//大于-1有权限显示
		return true;
	}else{//-1没有权限
		return false;
	}
}