<template>
    <div class="menus">
        <div class="menus-mai">
            <div class="menus-mai-logo" @click="gohome()">
                <div class="menus-mai-logo-img">
                    <img src="../../assets/img/logo4.png" alt="" srcset="">
                </div>
                <div class="menus-mai-logo-txt">
                    冷链v3.0
                </div>
            </div>
            <div class="menus-mai-list" style="width: 100%;">
                <el-menu default-active="" background-color="rgb(58,57,78)" class="el-menu-vertical-demo" menu-trigger="click"
                    :collapse="true" style="width: auto;border-right:none" router>
                    <el-submenu :index="'/' + item.T_permission + '/' + item.Children[0].T_permission"
                        v-for="item, index in roulist" :key="index">
                        <template slot="title">
                            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <i class="iconfont Oneicon" :class="item.T_icon" style="font-size:30px;"></i>
                                <div slot="title" style="color: #909399;font-size: 12px;">{{ item.T_name }}</div>
                            </div>
                        </template>
                        <el-menu-item class="el-submenus" :index="'/' + item.T_permission + '/' + item1.T_permission"
                                v-for="(item1, index1) in item.Children" :key="index1">
                                    <i class="iconfont" :class="item1.T_icon" style="font-size:16px;color: #fff;"></i>
                                    <span slot="title" style="color: #fff;padding-left: 10px;">{{ item1.T_name }}</span>
                            </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    computed: mapGetters(["navData"]),
    // computed:{//多个计算属性写法
    // 	add(){
    // 		return 'adv'
    // 	},
    // 	...mapGetters(["navData"])
    // },
    data() {
        return {
            roulist: [],
            NavData: null,
        }
    },
    watch: {
        navData: { // $route可以用引号，也可以不用引号  监听的对象
            handler(val) {
                this.roulist = this.navData
                ////console.log('重新设置菜单========3',val)
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        },
    },
    mounted() {
        ////console.log('daohang', this.navData)
    },
    methods: {
        clickNav(cocor) {
            // this.$store.commit('setnavNum', index)
        },
        searchs() {
            this.$message('功能正在开发中，敬请期待')
        },
        gohome() {
            this.$router.push({
                path: '/index'
            })
        },
        quan() {
            if (this.isScreenFull) {
                document.exitFullscreen()
            } else {
                document.documentElement.requestFullscreen()
            }
            this.isScreenFull = !this.isScreenFull // 直接在后边来个取反就好，就不用在if里赋值了
        },
    }
}
</script>

<style lang="scss">
.menus-mai {
    .menus-mai-logo {
        height: 100px;
        background: #ee954d;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        cursor: pointer;

        // box-shadow: 5px 5px 10px -10px #000;
        .menus-mai-logo-img {
            width: 35px;
            height: 35px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .menus-mai-logo-txt {
            font-size: 12px;
            font-weight: 100;
            transform: scale(1);
            letter-spacing: 1px;
            margin-top: 10px;
            padding: 0 10px;
        }

    }

    .menus-mai-list {
        cursor: pointer;

        .menus-mai-list-nav {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .menus-mai-list-nav1 {
                width: 100%;
                height: 100%;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .Oneicon {
                    color: #7a7f8f !important;
                }

                .Onefont {
                    color: #7a7f8f !important;
                    font-size: 12px;
                    transform: scale(.75);
                    letter-spacing: 1px;
                }

                .router-link-active {
                    background: #57566b;

                    .Oneicon {
                        color: #fff !important;
                    }

                    .Onefont {
                        color: #fff !important;
                        font-size: 12px;
                    }
                }
            }

            .menus-mai-list-nav2 {
                position: absolute;
                left: 70px;
                top: calc((100% - 30px)/2);
                z-index: 555;
                min-width: 120px;
                color: #000;
                background: #fff;
                border-radius: 2px;
                border: 1px solid #ebeef5;
                box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

                .menus-mai-list-nav2-san {
                    position: absolute;
                    top: 10px;
                    left: -12px;
                    display: block;
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                    border-left-width: 0;
                    border-right-color: #fff;
                    border-width: 6px;
                    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
                }

                .menus-mai-list-nav2-er {
                    background: #fff;
                    width: auto;
                    flex-direction: column;
                    min-height: 50px;

                    a {
                        color: #7a7f8f;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        padding: 13px 10px;

                        .aicon {
                            margin-right: 5px;
                        }

                        .a2 {
                            min-width: 100px;
                        }
                    }
                }

                .menus-mai-list-nav2-er a:hover {
                    background: rgba(#ccc, .1);
                }

                .router-link-active {
                    color: #ee954d !important;
                }
            }

            .nav2 {
                display: none;
            }
        }

        // .menus-mai-list-nav:hover {
        //     background: rgba(#000, .5);

        //     .menus-mai-list-nav1 {
        //         color: #ee954d !important;
        //     }
        // }

        // .menus-mai-list-nav:hover .nav2 {
        //     display: block;
        //     animation: names .3s;
        //     animation-fill-mode: none;
        // }

        @keyframes names {
            from {
                transform: translateX(100px);
                opacity: 0;
            }

            to {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}
</style>
<style lang="scss">
.el-menu--vertical .el-menu-item.is-active {
    background-color: #ee954d !important;
}

/* 自定义鼠标移入时菜单项的背景色和文字颜色 */

.el-submenu__title {
    height: 78px !important;
    padding:20px 0 !important;
}

.el-submenu__title {
    line-height: 28px !important;
}

.el-submenu__title:hover{
    background: rgb(29, 28, 39) !important;
}
// .el-menu-item:hover{
//     background: rgb(236, 245, 255) !important;
//     color: #666;
// }
.el-submenus{
    display: flex;
    align-items: center;
}

</style>