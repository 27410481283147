export function TimeData(time) {
    if (time) {
		var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let y = date.getFullYear();
		let MM = (date.getMonth() + 1).toString().padStart(2, '0');
		let d = date.getDate().toString().padStart(2, '0');
		let h = date.getHours().toString().padStart(2, '0');
		let m = date.getMinutes().toString().padStart(2, '0');
		let s = date.getSeconds().toString().padStart(2, '0');
		return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
	} else {
		return '0000-00-00  00-00-00'
	}
    
}
export function TimeDate(time) {//不要带秒
    if (time) {
		var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		let y = date.getFullYear();
		let MM = (date.getMonth() + 1).toString().padStart(2, '0');
		let d = date.getDate().toString().padStart(2, '0');
		let h = date.getHours().toString().padStart(2, '0');
		let m = date.getMinutes().toString().padStart(2, '0');
		let s = date.getSeconds().toString().padStart(2, '0');
		return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
	} else {
		return '0000-00-00  00-00'
	}
    
}