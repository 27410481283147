<template>
    <div @click="showModes" class="LockScreen"><!--@mousemove="showModes"-->
        <div style="display: flex;flex-direction: column;align-items: center;">
            <div style="font-size: 40px;font-weight: 100;margin-bottom: 30px;">{{ timeDate | GetTimeFun(timeDate) }}</div>
            <i class="el-icon-lock" style="font-size: 40px;font-weight: 100;"></i>
            <span class="iconStyle" style="margin-top: 20px;">已进入休眠模式(2分钟未操作自动进入休眠模式)，点击关闭休眠模式</span>
        </div>
    </div>
</template>
<script>
import {
    mapGetters
} from "vuex"
export default {
    computed: mapGetters(["showModel"]),
    filters: {
        GetTimeFun(value) {
            var hour = value.getHours().toString().padStart(2, '0')
            var min = value.getMinutes().toString().padStart(2, '0')
            var sec = value.getSeconds().toString().padStart(2,'0')
            return hour + ":" + min + ":" + sec
        }
    },
    mounted(){
       
    },
    watch:{
        $route:{
            handler(val){
                if(val.name!='realtime' || val.name!='companyRealtime'){
                    clearInterval(this.timer)
                    this.timer = null
                }else{
                    this.setTing()
                }
        },deep:true,immediate:true
        },
        showModel(val){
            if(val){
                this.setTing()
            }else{
                clearInterval(this.timer)
                this.timer = null
            }
        }
    },
    data() {
        return {
            timer: null,
            timeDate: new Date(),
        }
    },
    // mounted(){
    //     this.setTing()
    // },
    methods: {
        setTing(){
            var that = this
            this.timer = setInterval(function() {
                that.timeDate = new Date()
            }, 1000)
        },
        showModes() {
            this.$store.dispatch('SETMODEL', false)
        },
    }
}
</script>
<style lang="scss">
.LockScreen {
    width: 100%;
    height: 100vh;
    background: rgba(62, 66, 78, $alpha: 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // filter: blur(5px);//白边
    cursor: pointer;
}
.iconStyle{
    
    // transform:scale(-1,1);
    // -webkit-box-reflect:below 1px -webkit-linear-gradient(transparent,transparent 40%,rgba(0,0,0,.5));
}
</style>