import {
	Loading
} from 'element-ui';

let loadingCount = 0;
let loading;

const startLoading = () => {
	loading = Loading.service({
		lock: true,
		text: 'LODAING',
		background: 'rgba(0, 0, 0, .8)'
	});
};

const endLoading = () => {
	loading.close();
};

export const showLoading = () => {
	if (loadingCount === 0) {
		startLoading();
	}
	loadingCount += 1;
};

export const hideLoading = (i) => {
	if (loadingCount <= 0) {
		return;
	}
	if(i==1){
		loadingCount = 0;
	}else{
		loadingCount -= 1;
	}
	if (loadingCount === 0) {
		endLoading();
	}
};
