<!-- 显示菜单箭头 -->
<template>
    <div style="height: 50px;position: fixed;top:20px;left: 20px;width: 100%;display: flex;" @click="clickArrow">
        <div class="arrows">
            <img src="../../assets/img/btn-icon-1.png" style="width: 40px;height: 40px;">
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        clickArrow() {
            this.$router.replace('/intelligentsystem/home')
        }
    }
}
</script>
<style lang="scss">
.arrows{
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
</style>