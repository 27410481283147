<!-- 圆环 -->
<template>
    <div>
        <div class="Titlebar" data-aos="slide-down" :data-aos-delay="0">
            <slot name="TitlebarLeft"></slot>
            <slot name="TitlebarRight"></slot>
        </div>
    </div>
</template>

<script>
export default {
    components:{
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
<style lang="scss">
.Titlebar{
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 20px;
    border-bottom: 1px solid rgba(47,107,207,.2);
    margin-bottom:10px;
    color:#5fc5ff;
}
</style>