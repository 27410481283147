<template>
	<div class="inputs">
		<div class="inputs0 inputs0" v-show="inputShow0">
			<el-input v-model="input0" @change="inputser" :placeholder="inputPlace0" @keyup.enter.native="search" clearable></el-input>
		</div>
		<div class="inputs0 inputs1" v-show="inputShow">
			<el-input v-model="input" @change="inputs" :placeholder="inputPlace" @keyup.enter.native="search" clearable></el-input>
		</div>
		<div class="inputs0 inputs2" v-show="pickerShow">
			<el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
				start-placeholder="开始日期" end-placeholder="结束日期" @input="pickers">
			</el-date-picker>
		</div>
		<div class="inputs0 inputs3" v-show="selectShow">
			<el-select v-model="value2" :placeholder="selectPlace" @change="selects">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>
	</div>
</template>


<!-- 
	在界面上面使用组件：
	<inputs @inputs="inputValue" @pickers="pickersValue" :inputShow="true" :pickerShow="true"></inputs>
	1、需要显示的组件 :inputShow="true"（显示input框） 
					 :pickerShow="true"（显示时间区间选择器框） 
					 :selectShow="true" (显示select选择框)
					 :inputPlace="请输入***" （input显示placeholder）
	
	2、父组件获取值  @inputs="inputValue" （获取input框值） 
					@pickers="pickersValue" （获取时间区间选择器值） 
					
	3、使用select 需要父组件传值
				  options: [{
				  	value: '选项1',
				  	label: '黄金糕'
				  }, {
				  	value: '选项2',
				  	label: '双皮奶'
				  }, {
				  	value: '选项3',
				  	label: '蚵仔煎'
				  }, {
				  	value: '选项4',
				  	label: '龙须面'
				  }, {
				  	value: '选项5',
				  	label: '北京烤鸭'
				  }],
 -->
 
<script>
	export default {
		name: "",
		props: ["inputShow0","inputShow", "pickerShow", "selectShow", "inputPlace","inputPlace0", "options","selectPlace"],
		data() {
			return {
				input0:'',//一般用于出现2个输入框
				input: '',
				value1: '',
				value2: '',
			}
		},
		methods: {
			inputs(e) { //input输入
				this.$emit('inputs', e);
			},
			inputser(e){
				this.$emit('inputser', e);
			},
			pickers(e) { //时间区间选择
				this.$emit('pickers', e);
			},
			selects(e) {
				this.$emit('selects', e);
			},
			search(){//触发回车
				this.$emit('search')
			}
		}
	}
</script>

<style lang="scss">
	.inputs {
		display: flex;

		.inputs0 {
			margin-right: 10px;
		}
	}
</style>
