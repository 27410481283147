<template>
	<div class="hello">
		
		<div style="height: 100%;display: flex;flex-direction: column;position: relative;">
			<div class="showTime">
				<div>{{timeFindMin}}</div>
				<div>{{timeFindMax}}</div>
			</div>
			<Chart constructor-type="stockChart" ref="chartT" :options="chartOptions()" style="height:100%;"></Chart>
		</div>
	</div>
</template>

<script>
	import {
		Chart
	} from 'highcharts-vue';
	import {jisuabn,upLines,subtitleFun,plotLinesFun} from "@/plugins/calculateData-h"
	import { TimeData } from '@/plugins/timeFun';
	export default {
		props:['option','stale',"Fanstale"],
		watch:{
			stale(val){
				//console.log('4444444444444444H',val)
				this.list = []
				this.maxVal = 0
				this.minVal = 0	
				subtitleFun(this.list)
			},
			option:{
				handler(val){
					//console.log('555555555555555T1',val)
					this.$refs.chartT.chart.xAxis[0].setExtremes(null, null)//重置缩小
					this.findFun(val)
				},
				deep:true
			} 
		},
		components: {
			Chart
		},
		name: 'highcharts-t',
		computed:{
			timeFindMin(){
				if (this.list.length) {
					const mapArr = []
					for (const key of this.list) {
						key.data.forEach(item => {
							mapArr.push(item[0])
						});
					}
					return TimeData(Math.min(...mapArr))=='NaN-NaN-NaN NaN:NaN:NaN'?'':TimeData(Math.min(...mapArr))
				} else {
					return ''
				}
			},
			timeFindMax(){
				if (this.list.length) {
					const mapArr = []
					for (const key of this.list) {
						key.data.forEach(item => {
							mapArr.push(item[0])
						});
					}
					return TimeData(Math.max(...mapArr))=='NaN-NaN-NaN NaN:NaN:NaN'?'':TimeData(Math.max(...mapArr))
				} else {
					return ''
				}
			}
		},
		data() {
			return {
				list: [],
				maxVal:0,
				minVal:0,
				plotLines:[]
			}
		},
		created() {
			//console.log('图表',Chart)
			
			// let obj = {
			// 	name: 0,
			// 	data:[],
			// 	marker: {//显示曲线拐点的图形大小
			// 		enabled: true,
			// 		radius: 4
			// 	},
			// 	// shadow: true,//导航器中的曲线阴影
			// 	tooltip: {//tooltip中后面追加几位0
			// 		valueDecimals: 2
			// 	}
			// }
			// for(var i = 0;i<100;i++){
			// 	obj.data.push([this.time, Math.random()*10])
			// 	this.time = this.time + 60000
			// }
			// this.list.push(obj)
			// //console.log('结果',this.list)
		},
		
		methods: {
			async findFun(value){
				//console.log('数据',value)
				let arr = value.data
				if(arr.length==0){//传的空数据，存在list中删除，不存在忽略
					//console.log('传的空数据，存在list中删除')
					if(this.list.length!=0){
						const b = this.list.some(v => v.judge == value.sn + value.id)
						const j = this.list.findIndex(v => v.judge == value.sn + value.id)
						if(b){//等于true  存在其中 替换
							this.list.splice(j, 1)
						}
					}else{
						if(value.data[0].T_ish==2)return
						//console.log('当前图表有数据需要查询=====')						
						const b = this.list.some(v => v.judge == value.sn + value.id)
						const j = this.list.findIndex(v => v.judge == value.sn + value.id)
						//console.log('是否存在',b,j);
						if(b==true){//等于true  存在其中 替换
							//console.log('替换',this.list)
							this.list.splice(j, 1, this.calcuFun(value))
						}else{//不存在直接替换
							//console.log('添加',this.list)
							this.list.push(this.calcuFun(value))
						}
					}
				}else{//带了数据 存在替换，不存在添加
					if(this.list.length==0){
						this.list.push(this.calcuFun(value))
						//console.log('当前图表无数据直接添加=====')
					}else{
						//console.log('当前图表有数据需要查询=====')						
						const b = this.list.some(v => v.judge == value.sn + value.id)
						const j = this.list.findIndex(v => v.judge == value.sn + value.id)
						//console.log('是否存在',b,j);
						if(b){//等于true  存在其中 替换
							//console.log('替换',this.list)
							this.list.splice(j, 1, this.calcuFun(value))
						}else{//不存在直接替换
							//console.log('添加',this.list)
							this.list.push(this.calcuFun(value))
						}
					}
				}
				jisuabn(this.list)
				this.maxVal = upLines("max",this.list)
				this.minVal = upLines("min",this.list)
				this.plotLines = []
				//console.log('88888',this.plotLines)
				this.plotLines =  plotLinesFun(this.list)
				//console.log('99999',this.plotLines)
			},
			calcuFun(value){
				let arr = value.data
				let obj = {
					name: value.name,
					judge:value.sn + value.id,
					data:[],
				}
				
				for(var i = 0;i<arr.length;i++){
					obj.data.push([new Date(arr[i].T_time).getTime(), arr[i].T_rh, arr[i].T_rhu, arr[i].T_rhl])
				}
				return obj
			},
			chartOptions() {
				//console.log('9898',this.list)
				var that = this
				var obj = {
					// scrollbar: { //关闭滚动条
					// 	enabled: false
					// },
					// scrollbar: {//自定义滚动条样式
					// 	barBackgroundColor: '#ccc',
					// 	barBorderRadius: 7,
					// 	barBorderWidth: 0,
					// 	buttonBackgroundColor: 'gray',
					// 	buttonBorderWidth: 0,
					// 	buttonBorderRadius: 7,
					// 	trackBackgroundColor: 'none',
					// 	trackBorderWidth: 1,
					// 	trackBorderRadius: 8,
					// 	trackBorderColor: '#CCC'
					// },
					// navigator: {//关闭导航器
					// 	enabled: false
					// },
					chart: {
						zoomType: 'x',//框选放大
						type: 'spline' //图表类型,column,line,spline,area等
					},
					xAxis: {
						type: 'datetime',
					},
					time: {
						useUTC: false
					},
					boost: {
						useGPUTranslations: true
					},
					yAxis: {
						title: {
							text: '湿度Rh'
						},
						max:this.maxVal,
						min:this.minVal,
						opposite: false, //设置y轴提示在左边
						plotLines: this.plotLines
					},
					legend: {
						layout: 'horizontal',
						verticalAlign: 'top',
						enabled :true,
						align: 'left',
						y:22,
						x:22,
						floating:true,
					},
					rangeSelector: {
						buttons: [], // 不显示左边的zoom
						selected: 1,
						inputEnabled: false // 不显示日期输入框
					},
					title: {
						text: '湿度曲线图' 
					},
					exporting:{
						enabled:false //用来设置是否显示‘打印’,'导出'等
					},
					subtitle: {
						useHTML: true,
						text: subtitleFun(this.list)
					},
					dataGrouping: {
						enabled: true // 取消根据日期进行数据分组
					},
					plotOptions: {
						series: {
							marker: {
								enabled: true,
								radius: 3,
								// symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)'
							},
						}
					},
					// tooltip: {
					// 	valueSuffix: ' Rh',
					// 	xDateFormat: '%Y-%m-%d %H:%M:%S',
					// 	// headerFormat: '<small class="headerFormat">{point.key}:{point.stackTotal}</small><table>',
					// 	// valueDecimals: 0  //会导致提示框内容显示错误
					// },
					tooltip: {
						formatter: function () {
							var s = TimeData(this.points[0].x) + '<br/>';
							for (let i in this.points) {
								s += '<br/>' + `<div style='color:${this.points[i].series.color};padding-bottom:10px'>` + this.points[i].series.name + ': 【' + (this.points[i].y).toFixed(1) + 'Rh】</div>';
							}
							return s;
						},
						shared: false
					},
					// tooltip: {
					// 	split: false,
					// 	shared: true,
					// 	// headerFormat: '<span>{point.key}</span><br />',
					// 	xDateFormat: '%Y-%m-%d %H:%M:%S',
					// 	pointFormat: '<span style="color: {series.color};font-size:16px" colspan="2">{series.name} </span><br />' +
					// 		'<span>最高:</span><span style="text-align: right">{point.high}</span><br/>' +
					// 		'<span>最低:</span><span style="text-align: right">{point.low}</span><br/>'
					// },
					// chart: {
					// 	type: 'spline' //图表类型,column,line,spline,area等
					// },
					series: this.list
				}
				return obj
			}
		}
	}
</script>

<style scoped>
.showTime{
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* color: rgb(63, 177, 124); */
		color: #666;
		position: absolute;
		left: 65px;
		top: 35px;
		right: 10px;
		z-index: 9;
	}
</style>