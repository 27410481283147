let arrMap = []//取出map数据
let sum = 0 //和
let maxValue = 0 //最小值
let minValue = 0 //最大值
let average = 0  //平均值

let maxT = [] //取出所有线条的最新一个上限
let minT = [] //取出所有线条的最新一个下限


export function jisuabn(arrData){
	//console.log('添加计算',arrData)
	arrMap = []
	if(arrData.length!=0){
		for (let el of arrData) {
			for (let item of el.data) {
				arrMap.push(item[1])
			}
		}
		if(arrMap.length!=0){
			sum = arrMap.reduce((n,m) => n + m);//求和
			maxValue = Math.max(...arrMap).toFixed(1) //最大值
			minValue = Math.min(...arrMap).toFixed(1) //最小值
			average =  parseFloat(sum / arrMap.length).toFixed(1)//平均值
		}
		

		
	}else{
		arrMap = []
		sum = 0
		maxValue = 0
		minValue = 0
		average = 0	
	}
	//console.log('添加计算结束',sum,maxValue,minValue,average)
}


//配置副标题(完成)
export function subtitleFun(arr){
	//console.log('-------------------------------------------------------------subtitleFun789',arr)
	if(arr.length==0){
		return ''
	}else{
		const b = arr.every(item => item.data.length>0)
		if(b){
			return ` <div style="display: flex;align-items: center;justify-content: center;color: rgb(63, 177, 124);">
			<div style="margin-right: 20px;">最高温度：${maxValue}℃</div>
			<div style="margin-right: 20px;">最低温度：${minValue}℃</div>
			<div style="margin-right: 20px;">平均温度：${average}℃</div>
			
		</div>`
		// <div style="margin-right: 20px;">超标温度累计时长：${overheatFun(arr)}</div>
		}else{
			return ''
		}
		
	}
	
}
export function overheatFun(list){//超温计算
	// //console.log('====================================（当前是否超温）',list)
	let isSetTime = ''//开始时间
	let isEndTime = '' //结束时间
	let sumArr = [] //统计和的数组
	
	if(list.length!=0){
		for (const key of list) {
			//console.log('key',key)
			let arr = key.data
			//直接取当前sn数据数组的最新时间的数据作为上下限--计算比较
			let Upper = arr[arr.length-1][2]//上限
			let Under = arr[arr.length-1][3]//下限
			for (let i = 0;i<arr.length;i++) {
				if(Under<arr[i][1] && Upper>arr[i][1]){//当前这个点正常
					if(isSetTime==''){//当前点正常,但是开始时间没有记录，直接跳过进行下一次
						continue
					}else{ //当前点正常,存在了开始时间，计算结果
						isEndTime = new Date(arr[i][0]).getTime()
						sumArr.push(isEndTime-isSetTime)
						isSetTime = ''
						isEndTime = ''
					}
					if(isSetTime!='' && i==arr.length-1){//存在了开始时间  并且是最后一个了，直接计算 
						isEndTime = new Date(arr[i][0]).getTime()
						sumArr.push(isEndTime-isSetTime)
						isSetTime = ''
						isEndTime = ''
					}
				}else{//当前点异常
					if(isSetTime==''){//如果开始时间为空 那么设置超温起点
						isSetTime = new Date(arr[i][0]).getTime()
					}else{//已经存在了开始时间，当前点又为异常，直接跳过进行下一次
						if(i==arr.length-1){//当前点是最后一个  存在开始时间  结算
							isEndTime = new Date(arr[i][0]).getTime()
							sumArr.push(isEndTime-isSetTime)
							isSetTime = ''
							isEndTime = ''
						}
						
					}
				}
			}
		}
		
		if(sumArr.length!=0){
			
			// const times = 192800
			const times = ((sumArr.reduce((n,m) => n + m))/1000).toFixed(0)
			let days = parseInt(times / 60 / 60 / 24)=='00'?'':parseInt(times / 60 / 60 / 24)+'天'
			let hour = parseInt(times / 60 / 60 % 24).toString().padStart(2,'0')=='00'?'':parseInt(times / 60 / 60 % 24).toString().padStart(2,'0')+'时'
			let minute = parseInt(times / 60 % 60).toString().padStart(2,'0')=='00'?'':parseInt(times / 60 % 60).toString().padStart(2,'0')+'分'
			let secs = parseInt(times % 60).toString().padStart(2,'0')=='00'?'':parseInt(times % 60).toString().padStart(2,'0')+'秒'

			//console.log('*******************结果123*************',days +hour +minute +secs)
			return  days +hour +minute +secs
		}else{
			return  0
		}
	}else{
		return 0
	}
}
// export function overheatFun(list){//超温计算 （这个计算的当前超温点的前后时间都计算在内的）
// 	let isStart = 0//开始时间
// 	let isEnd = 0//结束时间
// 	let toStore = 0//判断当前点是否正常
// 	let sumArr = []
// 	//console.log('789',list)
// 	if(list.length!=0){
// 		for (const j of list) {
// 			let arr = j.data
			
// 			//直接取当前sn数据数组的最新时间的数据作为上下限--计算比较
// 			let tt = arr[arr.length-1][2]
// 			let bb = arr[arr.length-1][3]
			
// 			for (let i = 0;i<arr.length;i++) {
// 				//console.log('op==================================================================================',j,bb,arr[i][1],tt)
// 				if(i==0){//第一个
// 					if(bb<=arr[i][1] && tt>=arr[i][1]){//正常温度
// 						toStore = true
// 					}else{//异常温度
// 						toStore = false
// 					}
// 					isStart = new Date(arr[i][0]).getTime()
// 					//console.log('第一个时间',isStart)
// 					continue;
// 				}else{//除第一个的数据
// 					if(bb<=arr[i][1] && tt>=arr[i][1]){//当前正常温度
// 						if(toStore){//当前正常，上一个正常
// 							toStore = true
// 							isStart = new Date(arr[i][0]).getTime()
// 							continue;
// 						}else{//当前正常，上一个异常
// 							toStore = true
// 							isEnd = new Date(arr[i][0]).getTime()//结束时间赋值
// 							sumArr.push(isEnd-isStart)
// 							isStart = isEnd
// 							continue;
// 						}
// 					}else{//当前异常温度
// 						if(toStore){//当前异常，上一个正常
// 							toStore = false
// 							isEnd = new Date(arr[i][0]).getTime()//结束时间赋值
// 							sumArr.push(isEnd-isStart)
// 							isStart = isEnd
// 							continue;
// 						}else{//当前异常，上一个异常
// 							toStore = false
// 							isEnd = new Date(arr[i][0]).getTime()//结束时间赋值
// 							sumArr.push(isEnd-isStart)
// 							isStart = isEnd
// 							continue;
// 						}

						
// 					}
// 				}
// 			}
// 			//console.log('超温计算2=======',arr,sumArr)
// 		}
// 		if(sumArr.length!=0){
// 			return  ((sumArr.reduce((n,m) => n + m))/60000).toFixed(0)
// 		}else{
// 			return  0
// 		}
// 	}else{
// 		return 0
// 	}
// }
//计算y轴可视的最高点与最高点 值为nub(完成)
export function upLines(val,arrData){
	//console.log('arrData',val,arrData)
	let arr = []
	
	val=="max"?maxT = []:minT = []
	
	for(let el of arrData){
		if(el.data!=0){
			val=="max"?maxT.push(el.data[el.data.length-1][2]):minT.push(el.data[el.data.length-1][3])
			for(let obj of el.data){
				arr.push(obj[1])
			}
		}	
	}
	//console.log('上限区间',Math.max(...maxT),Math.min(...minT),arr)
	return val=="max"?Math.max(...maxT)>Math.max(...arr)?Math.max(...maxT):Math.max(...arr):Math.min(...minT)<Math.min(...arr)?Math.min(...minT):Math.min(...arr)
}

//配置上下限标线(完成)
export function plotLinesFun(hig){
	//console.log('重新设置标线848481888',maxT,minT)
	var arr = []
	var arrmax = [...new Set(maxT)]
	var arrmin = [...new Set(minT)]

	arrmax.forEach(item => {
		arr.push({
			color: '#FF1D00', //线的颜色，定义为红色
			dashStyle: 'solid', //默认值，这里定义为实线 虚线DashDot
			value: item, //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
			width: 2, //标示线的宽度，2px
			label: {
				text: `温度上限（${item}℃）`, //标签的内容
				align: 'right', //标签的水平位置，水平居左,默认是水平居中center
				// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
			}
		})
	})
	arrmin.forEach(item => {
		arr.push({
			color: '#FF1D00', //线的颜色，定义为红色
			dashStyle: 'solid', //默认值，这里定义为实线 虚线DashDot
			value: item, //定义在那个值上显示标示线，这里是在x轴上刻度为3的值处垂直化一条线
			width: 2, //标示线的宽度，2px
			label: {
				text: `温度下限（${item}℃）`, //标签的内容
				color: '#FF7804', //线的颜色，定义为红色
				align: 'right', //标签的水平位置，水平居左,默认是水平居中center
				// x: 10 //标签相对于被定位的位置水平偏移的像素，重新定位，水平居左10px
			}
		})
	})
	return arr
}