<template>
  <div class="pagination">
    <el-pagination background layout="total, prev, pager, next" :total="total"
    :current-page="PageNum" :page-size="pageSizi"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>  
    
<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    },
    pageSizi: {
      type: Number,
      default: 10
    },
    PageNum: {
      type: Number,
      default: 1
    }
  },
  methods: {
      handleCurrentChange(page) {
        this.$emit('currentChange', page);
      }
  }
};
</script>  
    
<style scoped>
::v-deep .el-pagination__total {
    color: #5fc5ff;
}
::v-deep .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: #183c7c;
    color: #5fc5ff;
    min-width: 30px;
    border-radius: 2px;
}
::v-deep .el-pagination .btn-prev,  
::v-deep .el-pagination .btn-next {  
    background-color: #183c7c !important;  
    color: #5fc5ff;
}
/* border: 2px solid #183c7c; 
.hoverBottom{
  transition: all .1s;
}
.hoverBottom:hover {  
  transform: scale(1.1);
  border-radius: 20px;
} 
.pagination button.active {  
  color:#5fc5ff;
  border-radius: 20px;
}   */

</style>