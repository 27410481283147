<template>
    <div class="headers">
        <div class="headers-nav">
            <gradationText text="宝智达冷链-智慧环境管理系统" size="200%"></gradationText>
        </div>
    </div>
</template>

<script>
import gradationText from "./gradationText.vue";
export default {
    components:{gradationText:gradationText}
}
</script>
<style lang="scss">
@import url('../assets/scss/headers.scss');
</style>