<!-- 搜索抽屉 -->
<template>
    <div class="Drawers">
        <el-drawer :title="titles" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
            <div class="drawers-conter">
                <el-form ref="pages" :model="pages" label-width="80px">
                    <el-form-item label="设备名称">
                        <el-input v-model="pages.T_name"></el-input>
                    </el-form-item>
                    <el-form-item label="状态查询">
                        <el-select v-model="pages.T_handle" placeholder="请选择状态查询">
                            <el-option :label="item.label" :value="item.value" v-for="item,index in options" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="历史记录">
                        <el-date-picker v-model="TimeData" type="datetimerange" @change="TimeChang" range-separator="至"
							value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
                    </el-form-item>
                    <el-form-item label="报警分类">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
							@change="handleCheckAllChange">全选</el-checkbox>
                            <el-checkbox-group v-model="pages.T_tp" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="item, index in dataPowerAll" :label="item.Id" :key="index">{{ item.T_name }}
                                </el-checkbox>
                            </el-checkbox-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">立即搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {
	WTall
} from "@/api/Warning.js"
export default {
    props: {
        titles: {
            type: String,
            default: ''
        },
        drawer: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'rtl'//ltr从左往右开,rtl从右往左开,ttb从上往下开,btt从下往上开
        },
    },
    data() {
        return {
            options: [{
                    value: '1',
                    label: '默认'
                },{
                    value: '2',
                    label: '全部'
                },{
                    value: '3',
                    label: '未处理'
                }
            ],
            TimeData: [],
            checkAll: true,
            isIndeterminate: false,//全选
            pages: {
				T_name: '',
				T_tp: [],
				T_handle:'1', //1 默认 2 全部记录 3未处理
				Time_start: '',
				Time_end: '',
				T_admin: 0,
                T_history: 0,
				page: 1,
				page_z: 10,
			},
            dataPowerAll: [], //报警选择
        }
    },
    created() {
    },
    methods: {
        
        handleCheckAllChange(e) {//全选
			e ? this.dataPowerAll.forEach(item => { this.pages.T_tp.push(item.Id) }) : this.pages.T_tp = []
			this.isIndeterminate = false;//全选状态
		},
        async SetWarning() {
			var pwAll = await this.GetWTallApi()
			if (pwAll.data.Code === 200) {
				this.checkAll = true;//全选状态
				this.isIndeterminate = false
				var arr = pwAll.data.Data
				this.pages.T_tp = []
				this.dataPowerAll = arr
				arr.forEach(item => {
					this.pages.T_tp.push(item.Id)
				})
			}
		},
        GetWTallApi() { //获取报警权限
			return new Promise(resolve => {
				WTall({}).then(res => {
					resolve(res)
				})
			})
		},
        handleCheckedCitiesChange(value) {
			////console.log('danxuan', value)
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.dataPowerAll.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataPowerAll.length;
		},
        TimeChang(e) { //选择完时间确定按钮
			this.pages.Time_start = e[0]
			this.pages.Time_end = e[1]
		},
        onSubmit() {
            var obj = { ...this.pages }
			obj.T_tp.length != 0 ? obj.T_tp = "T" + obj.T_tp.join('|T') + "|" : obj.T_tp = ''
            obj.Time_start =this.TimeData[0]
			obj.Time_end =this.TimeData[1]
            console.log('搜索',this.TimeData)
            this.$emit('searchFun',obj)
            this.$emit('update:drawer', false)
        },
        handleClose() {
            this.$emit('update:drawer', false)
        }
    }
}
</script>
<style scoped>
::v-deep .el-drawer {
    background-color: #001440 !important;
}

::v-deep .el-drawer__header {
    color: #5fc5ff;
    justify-content: center;
}

::v-deep .el-drawer__header>:first-child {
    flex: 1;
    font-size: 20px;
    font-weight: bold
}
::v-deep .el-input__inner {
    text-align: left !important;
}
::v-deep .el-date-editor .el-range-input {
    color: #fff;
    background: transparent;
}
::v-deep .el-date-editor .el-range-separator {
    color: #5fc5ff;
}
::v-deep .el-checkbox {
    color: #fff;
}
</style>
<style>
    .drawers-conter{
        height: 100%;
        background: url(../assets/img/bg3.png) no-repeat;
        background-size: 100% 100%;
        background-position: bottom center;
        overflow-y: auto;
        margin:0 20px
    }
</style>